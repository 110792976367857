<template>
    <div
      style="width: 1200px; margin: 20px auto 20px auto"
      element-loading-text="拼命加载中"
      v-loading="loading"
    >
      <el-row type="flex" justify="end" style="margin-bottom: 10px">
        <!-- <el-button @click="resetForm('ruleForm')">取 消</el-button> -->
        <el-button
          type="primary"
          plain
          icon="el-icon-circle-plus"
          @click="addTitleClick()"
          >添加</el-button
        >
        <!-- <el-button
          type="primary"
          plain
          icon="el-icon-circle-plus"
          @click="addClick()"
          >添加问题</el-button
        > -->
      </el-row>
      <div class="content">
        <el-card
          class="box-card"
          v-for="(item, index) in questions"
          :key="item.code"
        >
          <div slot="header" class="clearfix">
            <span class="clearfix-title">
              <span v-if="item.code">{{ index }}.</span> {{ item.title }} （{{ item.code }}）</span
            >
            <div style="float: right; padding: 3px 0">
              <!-- <el-button type="text">操作按钮</el-button> -->
              <el-button
                type="primary"
                plain
                icon="el-icon-circle-plus"
                @click="addOptionClick(item, index)"
                >添加</el-button
              >
              <el-button
                type="warning"
                plain
                icon="el-icon-edit"
                @click="editClick(item, index)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                plain
                icon="el-icon-delete-solid"
                @click="deleteClick(index)"
                >删除</el-button
              >
            </div>
          </div>
          <div v-if="item.input_options">
            <div
              v-for="(option, indexs) in item.input_options.items"
              :key="option.code"
              class="text item"
            >
              <el-row type="flex" justify="space-between">
                <el-col>
                  <span>
                    {{ index }}.{{ indexs + 1 }}
                    {{ option.text }} （{{ option.code }}）
                  </span>
                </el-col>
                <el-col style="text-align: right">
                  <el-button
                    type="warning"
                    plain
                    icon="el-icon-edit"
                    @click="editOptionClick(option, indexs, item)"
                    >编辑</el-button
                  >
                  <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete-solid"
                    @click="deleteOptionClick(index, indexs)"
                    >删除</el-button
                  >
                </el-col>
              </el-row>
            </div>
          </div>
        </el-card>
      </div>
      <el-card class="box-card bottom-card" style="margin-top: 10px">
        <el-row type="flex" justify="end">
          <!-- <el-button @click="resetForm('ruleForm')">取 消</el-button> -->
          <el-button type="primary" @click="submitForm">提 交</el-button>
        </el-row>
      </el-card>
  
      <!-- 弹出弹窗选择添加标题，添加问题，添加选项 -->
      <el-dialog title="选择添加内容" :visible.sync="dialogFormVisible">
        <el-radio-group v-model="radio" v-if="radioList.length > 0">
          <el-radio
            v-for="(item, index) in radioList"
            :key="index"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
        <div style="margin-top: 20px">
          <model
            v-if="radio == 1"
            ref="model"
            :detail="detailObj"
            @submitClick="submitClick"
          ></model>
          <titleModel
            v-if="radio == 0"
            ref="titleModel"
            :detail="detailObj"
            @submitTitleClick="submitTitleClick"
          ></titleModel>
          <optionModel
            v-if="radio == 2"
            ref="optionModel"
            :detail="detailObj"
            @submitItemClick="submitItemClick"
          ></optionModel>
        </div>
        <!-- <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addSubmitClick">确 定</el-button>
        </div> -->
      </el-dialog>
    </div>
  </template>
  <script>
  import model from "../components/model.vue";
  import optionModel from "../components/optionModel.vue";
  import titleModel from "../components/titleModel.vue";
  // import { getQuestions } from '@/api/wenjuan'
  import { templateGetIdApi, templateUpdateApi } from "@/api/templateApi.js";
  export default {
    components: { model, optionModel, titleModel },
    data() {
      return {
        loading: true,
        questions: [],
        detail: {},
        detailObj: {},
        radio: 0,
        dialogFormVisible: false,
        row: {},
        radioList: [],
        index: null,
        type:'add'
      };
    },
    created() {
      if (this.$route.query.id) {
        this.templateGetIdFun();
      }
    },
    watch: {
      radio: {
        handler(newVal, oldVal) {
          console.log('this.type', this.type)
          if (this.type != "edit") {
            this.addSubmitClick(newVal);
          }
        },
        deep: true,
      },
    },
    methods: {
      async templateGetIdFun() {
        console.log("oooo");
        var params = {
          id: this.$route.query.id,
        };
        var res = await templateGetIdApi(params);
        console.log("模版", res);
        this.detail = res.data;
        this.questions = res.data.survey.questions;
        this.loading = false;
      },
  
      // 添加标题
      addTitleClick() {
        this.radioList = [
          {
            value: 0,
            label: "添加标题",
          },
          {
            value: 1,
            label: "添加问题",
          },
        ];
        this.dialogFormVisible = true;
      },
      // 选项添加问题 、添加标题、添加选项
      addSubmitClick(radio) {
        setTimeout(() => {
          if (radio == 0) {
            console.log("添加标题");
            // 添加标题
            this.$refs.titleModel.open("add", {}, this.questions.length + 1);
          } else if (radio == 1) {
            // 添加问题
            this.$refs.model.open("add", {}, this.questions.length + 1);
          } else if (radio == 2) {
            // 添加选项
            this.$refs.optionModel.open(
              this.row,
              "add",
              this.row.input_options.items.length + 1
            );
          }
        }, 100);
      },
      // 点击行内添加
      addOptionClick(row, index) {
        this.radio = 0;
        this.row = row;
        this.type='add'
        this.radioList = [];
        if (row.code) {
          this.radioList = [
            {
              value: 0,
              label: "添加标题",
            },
            {
              value: 1,
              label: "添加问题",
            },
            {
              value: 2,
              label: "添加选项",
            },
          ];
        } else {
          this.radioList = [
            {
              value: 0,
              label: "添加标题",
            },
            {
              value: 1,
              label: "添加问题",
            },
          ];
        }
        this.index = index;
        this.addSubmitClick(this.radio)
        this.dialogFormVisible = true;
      },
      // 编辑标题
      editClick(row, index) {
        this.type = "edit";
        this.radioList=[]
        this.dialogFormVisible = true;
        if (row.code) {
          this.radio = 1;
          setTimeout(() => {
            this.$refs.model.open("edit", row, index + 1);
          }, 100);
        } else {
          this.radio = 0;
          setTimeout(() => {
            this.$refs.titleModel.open("edit", row, index + 1);
          }, 100);
        }
      },
      // 编辑单个选项
      editOptionClick(row, index, item) {
        this.radioList=[]
        this.type = "edit";
        this.dialogFormVisible = true;
        this.radio = 2;
        setTimeout(() => {
          this.$refs.optionModel.open(row, "edit", index + 1, item);
        }, 100);
      },
      // 删除问题
      deleteClick(index) {
        var questions = JSON.parse(JSON.stringify(this.questions));
        // 删除提示
        this.$alert(
          "这确定要删除" + questions[index].title + "问题吗？删除后将无法恢复",
          "删除提示",
          {
            confirmButtonText: "确定",
            callback: async (action) => {
              if (action === "confirm") {
                questions.splice(index, 1);
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.questions = questions;
              }
            },
          }
        );
      },
      // 删除选项
      deleteOptionClick(index, indexs) {
        var questions = JSON.parse(JSON.stringify(this.questions));
        // 删除提示
        this.$alert(
          "这确定要删除" +
            questions[index].input_options.items[indexs].text +
            "选项吗？删除后将无法恢复",
          "删除提示",
          {
            confirmButtonText: "确定",
            callback: async (action) => {
              if (action === "confirm") {
                questions[index].input_options.items.splice(indexs, 1);
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.questions = questions;
              }
            },
          }
        );
      },
      // 提交内容
      submitForm() {
        var survey = this.detail.survey;
        survey.questions = this.questions;
        console.log("survey", survey);
        this.detail.wjtData = JSON.stringify(survey, null, "\t");
        this.updateTemplateUpdateApi();
      },
      async updateTemplateUpdateApi() {
        delete this.detail.time;
        delete this.detail.survey;
        console.log("this.detail", this.detail);
        var res = await templateUpdateApi(this.detail);
        if (res.code === 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.$router.go(-1);
        }
      },
      // 添加问题
      submitClick(value) {
        var count = 0;
        value.input_name = value.code;
        if (this.questions.length > 0) {
          // 编辑问题
          this.questions.map((item, index) => {
            if (item.code === value.code) {
              this.questions.splice(index, 1, value);
            } else {
              count++;
            }
          });
          //  多次添加问题
          console.log("添加新问题value", value);
          if (this.questions.length === count) {
            if (
              value.input_type !== "number" &&
              value.input_type !== "text" &&
              value.input_type !== "textarea"
            ) {
              value.input_options = {
                inline: "1",
                items: [],
              };
            }
  
            if (this.index) {
              this.questions.splice(this.index + 1, 0, value);
            } else {
              this.questions.push(value);
            }
          }
        } else {
          console.log("第一次添加问题", value);
          // 第一次新增问题
          value.input_options = {
            inline: "1",
            items: [],
          };
          this.questions.push(value);
        }
        this.dialogFormVisible = false;
      },
      // 添加标题
      submitTitleClick(value) {
        var count = 0;
        this.questions.map((item, index) => {
          if (item.id === value.id) {
            this.questions.splice(index, 1, value);
          } else {
            count++;
          }
        });
        if (this.questions.length === count) {
          console.log("eee", this.index);
          if (this.index != null) {
            console.log("ee1111e", this.index);
            this.questions.splice(this.index + 1, 0, value);
          } else {
            this.questions.push(value);
          }
        }
        this.dialogFormVisible = false;
      },
      // 添加选项
      submitItemClick(value) {
        this.questions.map((item, index) => {
          if (item.code === value.code) {
            this.questions.splice(index, 1, value);
          }
        });
        this.dialogFormVisible = false;
      },
    },
  };
  </script>
  <style scoped>
  ::v-deep .el-card__header {
    padding: 12px 20px;
    background-color: rgba(250, 250, 250, 1);
  }
  .box-card {
    margin-bottom: 10px;
  }
  ::v-deep .box-card .el-button {
    padding: 5px;
  }
  .item {
    padding: 8px;
    border-bottom: 1px solid rgba(232, 232, 232, 1);
    font-size: 16px;
  }
  .clearfix-title {
    font-size: 18px;
    font-weight: bold;
  }
  .content {
    height: calc(100vh - 270px);
    overflow: auto;
  }
  .content::-webkit-scrollbar {
    width: 7px;
  }
  .content::-webkit-scrollbar-track {
    background-color: #fff;
  }
  .content::-webkit-scrollbar-thumb {
    background-color: #ddd;
  
    border-radius: 20%;
  }
  </style>
  