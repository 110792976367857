<template>
    <div>
      <!-- <el-dialog title="标题信息" :visible.sync="dialogVisible" width="50%"> -->
        <div>
          <el-form ref="form" :model="form" :rules="rules" label-width="120px">
            <el-form-item label="标题：" prop="title">
              <el-input
                v-model="form.title"
                placeholder="请输入（必填）"
              ></el-input>
            </el-form-item>
            <el-form-item label="副标题">
              <el-input v-model="form.title_as" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="显示/隐藏">
              <el-radio-group v-model="form.hide">
                <el-radio :label="0">显示</el-radio>
                <el-radio :label="1">隐藏</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="显示索引">
              <el-radio-group v-model="form.show_index">
                <el-radio :label="1">显示</el-radio>
                <el-radio :label="0">不显示</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitClick('form')">确 定</el-button>
        </span>
      <!-- </el-dialog> -->
    </div>
  </template>
  <script>
  
  import { v4 as uuidv4 } from 'uuid';
  export default {
    data() {
      return {
        dialogVisible: true,
        // item:{},
        form: {},
        index: undefined,
        rules: {
          title: [{ required: true, message: "请输入标题", trigger: "blur" }],
          title_as: [{ required: true, message: "请输入副标题", trigger: "blur" }],
        },
      };
    },
    methods: {
      open(type, row, index) {
        console.log('type, row, index', type, row, index)
        this.index = index;
        this.dialogVisible = true;
        if (type === "edit") {
          this.form = row;
        } else {
          this.form = {};
        }
      },
      submitClick(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.dialogVisible = false;
            if(!this.form.id){
              const id = uuidv4(); // 生成唯一的ID
              this.form.id = id;
            }
            this.$emit("submitTitleClick", this.form);
          } else {
            console.log("error submit!!");
            this.$message({
              type: "error",
              message: "请检查是否有提示选项",
            });
            return false;
          }
        });
      },
    },
  };
  </script>
  <style>
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
  }
  </style>
  