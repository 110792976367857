<template>
    <div>
      <!-- <el-dialog title="选项信息" :visible.sync="dialogVisible" width="50%"> -->
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="标题：" prop="text">
            <el-input v-model="form.text" placeholder="请输入（必填）"></el-input>
          </el-form-item>
          <!-- 与value值相同 -->
          <el-form-item label="值：" prop="code">
            <el-input
              v-model="form.code"
              disabled
              placeholder="请输入"
            ></el-input>
            <div>
              （等于编码）<el-button type="primary" size="mini" @click="codeClick"
                >获取选项编码</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="问题互控参数：">
            <el-input
              v-model="form.switch_target"
              placeholder="请输入"
            ></el-input>
            <div>
              选中某个问题时，格式：问题n的code |
              控制参数（多个以英文逗号隔开，详细请参考问卷参数）
            </div>
          </el-form-item>
          <el-form-item label="问题互控参数2：">
            <el-input
              v-model="form._switch_target"
              placeholder="请输入"
            ></el-input>
            <div>
              未选中某个问题时，格式：问题n的code |
              控制参数（多个以英文逗号隔开，详细请参考问卷参数）
            </div>
          </el-form-item>
          <el-form-item label="限制选项选择最大占比100：">
            <el-input v-model="form.group100" placeholder="请输入"></el-input>
            <div>（标记选项）</div>
          </el-form-item>
          <el-form-item label="限制选项选择最大占比100：">
            <el-input v-model="form.group100_val" placeholder="请输入"></el-input>
            <div>（选中代表值）</div>
          </el-form-item>
          <el-form-item label="互控条件">
            <el-input v-model="form.switch_need" placeholder="请输入"></el-input>
            <div>（配合switch_target使用）</div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitClick('form')">确 定</el-button>
      </span>
      <!-- </el-dialog> -->
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        dialogVisible: false,
        item: {},
        form: {},
        rules: {
          text: [{ required: true, message: "请输入标题", trigger: "blur" }],
          code: [{ required: true, message: "请输入值", trigger: "blur" }],
        },
        index: undefined,
      };
    },
    methods: {
      // 获取问题编码
      codeClick() {
        // 根据字母 + 年 +月 生成编码
        // 获取月份
        var date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
  
        var second, third;
        var yearStr = year.toString();
        second = yearStr.slice(2);
        if (month < 10) {
          third = "0" + month;
        }
  
        var code = "O" + second + third + this.index;
        this.$set(this.form, "code", code);
      },
      open(row, type, index, item) {
        this.index = index;
        this.dialogVisible = true;
        if (type === "add") {
          this.item = row;
          this.form = {};
        } else if (type === "edit") {
          this.form = row;
          this.item = item;
        }
      },
      submitClick(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.dialogVisible = false;
            var count = 0;
            this.form.value = this.form.code;
            this.form._switch_target = this.form._switch_target;
            delete this.form._switch_target;
            console.log('this.item', this.item)
            if (this.item.input_options.items.length > 0) {
              this.item.input_options.items.map((item, index) => {
                if (item.code === this.form.code) {
                  this.item.input_options.items.splice(index, 1, this.form);
                } else {
                  count++;
                }
              });
              if (this.item.input_options.items.length === count) {
                this.item.input_options.items.push(this.form);
              }
            } else {
              this.item.input_options.items.push(this.form);
            }
            this.$emit("submitItemClick", this.item);
          } else {
            this.$message({
              type: "error",
              message: "请检查是否有提示选项",
            });
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style>
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
  }
  </style>