<template>
    <div>
      <!-- <el-dialog title="问题信息" :visible.sync="dialogVisible" width="50%"> -->
        <div>
          <el-form ref="form" :model="form" :rules="rules" label-width="120px">
            <el-form-item label="问题名称：" prop="title">
              <el-input
                v-model="form.title"
                placeholder="请输入（必填）"
              ></el-input>
            </el-form-item>
            <el-form-item label="副问题名称：">
              <el-input v-model="form.title_as" placeholder="请输入"></el-input>
            </el-form-item>
            <!-- 需要向 input_name 写入相同的值-->
            <el-form-item label="问题编码" prop="code">
              <el-input
                v-model="form.code"
                disabled
                placeholder="请输入（必填）"
              ></el-input>
              <div>
                （唯一）
                <el-button type="primary" size="mini" @click="codeClick"
                  >获取问题编码</el-button
                >
              </div>
            </el-form-item>
            <el-form-item label="问题类型" prop="input_type">
              <el-radio-group v-model="form.input_type">
                <el-radio label="checkbox">checkbox（多选）</el-radio>
                <el-radio label="radio">radio（单选）</el-radio>
                <el-radio label="select">select（下拉选择）</el-radio>
                <el-radio label="number">number（数字）</el-radio>
                <el-radio label="text">text（文本）</el-radio>
                <el-radio label="textarea">textarea（多行文本）</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="问题显示容器样式名">
              <el-radio-group v-model="form.class">
                <el-radio label="hide">hide（问题不显示）</el-radio>
                <el-radio label="sub-question">sub-question（子问题）</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="显示/隐藏">
              <el-radio-group v-model="form.hide">
                <el-radio :label="1">隐藏</el-radio>
                <el-radio :label="0">显示</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="默认值">
              <el-input
                v-model="form.input_default_value"
                placeholder="请输入"
              ></el-input>
              <div>对于问题类型为：checkbox、select、radio时设置为选项的code值，对number、text、textarea等设置为初始填入内容</div>
            </el-form-item>
            <el-form-item label="用户输入提示">
              <el-input
                v-model="form.input_placeholder"
                placeholder="请输入"
              ></el-input>
              <div>（显示在输入框内）</div>
            </el-form-item>
            <el-form-item label="用户输入帮助">
              <el-input v-model="form.input_help" placeholder="请输入"></el-input>
              <div>（显示在输入框外）</div>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-radio-group v-model="form.input_must">
                <el-radio :label="1">必填</el-radio>
                <el-radio :label="0">非必填</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="问题表单域样式">
              <el-input
                v-model="form.input_class"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
  
            <el-form-item label="推送选项">
              <el-input
                v-model="form.input_send_to"
                placeholder="请输入"
              ></el-input>
              <div>（1推送，不写不推送）</div>
            </el-form-item>
            <el-form-item label="显示索引">
              <el-radio-group v-model="form.show_index">
                <el-radio :label="1">显示</el-radio>
                <el-radio :label="0">不显示</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="问题位置">
              <el-input v-model="form.append_to" placeholder="请输入"></el-input>
              <div>（问题code-答案code）</div>
            </el-form-item>
            <el-form-item label="最大选择个数">
              <el-input
                v-model="form.input_selected_max"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="最小选择个数">
              <el-input
                v-model="form.input_selected_min"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="最大值">
              <el-input v-model="form.input_max" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="最小值">
              <el-input v-model="form.input_min" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="来源企业库字段">
              <el-input
                v-model="form.input_default_from"
                placeholder="请输入"
              ></el-input>
              <div>（读取企业库那个字段的值）</div>
            </el-form-item>
            <el-form-item label="是否显示标题">
              <el-input
                v-model="form.title_hidden"
                placeholder="请输入"
              ></el-input>
              <div>1不显示标题</div>
            </el-form-item>
            <el-form-item label="是否自动填充历史答卷数据">
              <el-radio-group v-model="form.input_history">
                <el-radio :label="1">自动填充</el-radio>
                <el-radio :label="2">手动填充</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitClick('form')">确 定</el-button>
        </span>
      <!-- </el-dialog> -->
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        dialogVisible: false,
        // item:{},
        form: {},
        index: undefined,
        rules: {
          title: [{ required: true, message: "请输入问题名称", trigger: "blur" }],
          code: [{ required: true, message: "请输入问题编码", trigger: "blur" }],
          input_type: [
            {
              required: true,
              message: "请选择问题类型",
              trigger: "blur",
            },
          ],
        },
      };
    },
    methods: {
      // 获取问题编码
      codeClick() {
        // 根据字母 + 年 +月 生成编码
        // 获取月份
        var date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
  
        var second, third;
        var yearStr = year.toString();
        second = yearStr.slice(2);
        if (month < 10) {
          third = "0" + month;
        }
  
        var code = "SD" + second + third + this.index;
      //   this.form.code = code;
        this.$set(this.form, "code", code);
      },
      open(type, row, index) {
        console.log('dddd', type, row, index)
        this.index = index;
        if (type === "edit") {
          this.form = row;
        } else {
          this.form = {};
        }
      },
      submitClick(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.dialogVisible = false;
            this.$emit("submitClick", this.form);
          } else {
            console.log("error submit!!");
            this.$message({
              type: "error",
              message: "请检查是否有提示选项",
            });
            return false;
          }
        });
      },
    },
  };
  </script>
  <style>
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
  }
  </style>
  