import request from '@/utils/request.js'

// 模板列表
export function templateListApi(data) {
  return request({
    url: '/doftec/admin-survey-template/list',
    method: 'post',
    data: data
  })
}

// 修改模板
export function templateUpdateApi(data) {
  return request({
    url: '/doftec/admin-survey-template/update.u',
    method: 'post',
    data: data
  })
}

// 修改模板内容
export function templateUpdateContentApi(data) {
  return request({
    url: '/doftec/admin-survey-template/question.u',
    method: 'post',
    data: data
  })
}

// 新增模板
export function templateAddApi(data) {
  return request({
    url: '/doftec/admin-survey-template/create',
    method: 'post',
    data: data
  })
}

// 删除模板 ids:[], menuType 操作状态：delete 删除 push 推送 download 下载
export function templateDeleteApi(data) {
  return request({
    url: '/doftec/admin-survey-template/action',
    method: 'post',
    data: data
  })
}

// 根据id查询问卷模板
export function templateGetIdApi(params) {
  return request({
    url: '/doftec/admin-survey-template/get',
    method: 'get',
    params: params
  })
}

// 根据id查询问卷模板内容
export function templateGetIdContentApi(id) {
  return request({
    url: '/api/survey/get/template/'+id,
    method: 'get'
  })
}